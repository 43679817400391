export const checkIsDesktop = () => window.innerWidth > 1024;

const fillZero = (str) => parseInt(str)<10?'0'+str:str;

export const getDateKey = (date) => {
    return date.getFullYear() + '-' + fillZero(date.getMonth()+1) + '-' + fillZero(date.getDate());
};

export const scrollToElem = (elem) => {
    const scrollContainer = checkIsDesktop() ? window : document.querySelector('.site-wrapper');
    let scrollTop = 0;
    if (elem) {
        scrollTop = elem.getBoundingClientRect().top + getScroll() - window.innerHeight*0.15;
    }

    scrollContainer.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
    })

    function getScroll() {
        let result
        if(checkIsDesktop()) {
            result = window.scrollY;
        } else {
            result = scrollContainer.scrollTop - 120;
        }
        return result 
    }
}