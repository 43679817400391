import React, { useState, useEffect, useRef } from 'react';
import { IMG_BASE } from '../config';
import InputMask from 'react-input-mask';
import ArrowBlank from "../svg/arrow_blank.svg";
import { scrollToElem } from '../helpers';
import { sendContactForm } from '../api/index';
import InfoPopup from "../components/infoPopup";
import { FormDefaultValues } from "../mock/index";

const Contacts = ({ socialList}) => {
    const [hasErrors, setErrors] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [inputValues, setInputValues] = useState(FormDefaultValues);
    const inputForm = useRef(null);
    const preparedSocialList = socialList.reduce((acc, item) => ({
        ...acc,
        [item.name.toLowerCase()]: item
    }), {});

    useEffect(() => {
        if (hasErrors) {
            scrollToElem(inputForm.current.querySelector('._error'));
        }
    }, [hasErrors]);

    const {email, whatsapp, phone, telegram, instagram, facebook, linkedin} = preparedSocialList;
    const contactList = [email, whatsapp, phone, telegram];
    const followList = [instagram, facebook, linkedin];
    const modalContent = {
        name:'Message sended',
        description_column_1:'',
        description_column_2:''
    };

    function handleChange(e) {
        const {name, value, checked} = e.target;
        const changes = e.target.type === 'checkbox' ? {checked: checked} : {value: value};

        setInputValues({
            ...inputValues,
            [name]: {
                ...inputValues[name],
                ...changes,
                error: false,
            }
        });
    }

    const isFormCorrect = () => {
        const regexp = {
            message: /.+/, // хотя бы один символ
            name: /.+/, // хотя бы один символ
            phone: /\+\d{3} \d{4,15}/, // +852 9999-9999
            email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // t@t.t где t - хотя бы один символ
        };

        const errors = [];
        Object.entries(inputValues).forEach(([name, data]) => {
            if(data.type === 'text' && data.name!=='qr_only' && !regexp[name].test(data.value) ) {
                addError(name);
            } else if(data.type === 'checkbox' && !data.checked) {
                addError(name)
            }
        });

        function addError(name) {
            errors.push(name);
        }

        const updatedFields = errors.reduce((acc, name) => {
            return  {
                ...acc,
                [name]: {
                    ...acc[name],
                    error: errors.some(i => i === name),
                }
            }
        }, inputValues);

        setInputValues({
            ...updatedFields,
        });

        setErrors(errors.length);

        return !errors.length
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!isFormCorrect()) return

        const {name, phone, email, message} = inputValues;

        const data = {
            name: name.value,
            phone: phone.value,
            email: email.value,
            message: message.value,
        }
        sendContactForm(data).then(()=>setIsPopupOpen(true));
    }

    return (

        <div className="site-section">
            <h1 className="content-section__title">Ready to teleport your brand?</h1>
            <div className="visit__content">
                <div className="container">
                    <div className="visit__grid">
                        <div className="visit__left">
                            <h3>CONNECT WITH US TODAY – <br/>LET’S CREATE THE FUTURE TOGETHER!</h3>
                            <div className="content-section__text">
                                <p>
                                    HONG KONG<br/>
                                    7/F, Sun House, 90 Connaught Road Central, Sheung Wan, Hong Kong
                                </p>
                                <p>
                                    AUSTRALIA<br/>
                                    28/F, 161 Castlereagh Street, Sydney, NSW 2000
                                </p>
                            </div>
                            <div className="visit__left-wrapper">
                                <div className="visit__contacts">
                                    <h3 className="visit__title">Contact&nbsp;us</h3>
                                    <ul className="visit__contacts-list">

                                        {contactList.map((item) => (
                                            <li key={item.id} className="visit__contacts-item">
                                                <a href={item.url} className="visit__contacts-link" target="_blank" rel="noreferrer">
                                                    <img src={`${IMG_BASE}/${item.icon}`}className="visit__contacts-icon" alt=""/>
                                                    <span>{item.text}</span>
                                                </a>
                                            </li>
                                        ))}

                                    </ul>
                                </div>
                                <div className="visit__social">
                                    <h3 className="visit__title">Follow&nbsp;us</h3>
                                    <ul className="visit__social-list">

                                        {followList.map(item => (
                                            <li className="visit__social-item" key={item.id}>
                                                <a href={item.url} className="visit__social-link" target="_blank" rel="noreferrer">
                                                    <img src={`${IMG_BASE}/${item.icon}`}alt="" className="visit__social-icon"/>
                                                </a>
                                            </li>
                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="visit__right">
                            <div className="user-form__input-list">
                                <form onSubmit={handleSubmit} ref={inputForm}>
                                    <input className={`user-form__input ${inputValues.name.error ? '_error' : ''}`}
                                           type="text"
                                           name={inputValues.name.name}
                                           placeholder="Full name"
                                           onChange={handleChange}
                                    />
                                    <InputMask className={`user-form__input ${inputValues.phone.error ? '_error' : ''}`}
                                               type="text"
                                               name={inputValues.phone.name}
                                               placeholder="Phone number"
                                               mask="+999 999999999999"
                                               maskChar=""
                                               onChange={handleChange}
                                    />
                                    <input className={`user-form__input ${inputValues.email.error ? '_error' : ''}`}
                                           type="text"
                                           name={inputValues.email.name}
                                           placeholder="Email address"
                                           onChange={handleChange}
                                    />
                                    <textarea className={`user-form__input ${inputValues.message.error ? '_error' : ''}`}
                                              name={inputValues.message.name}
                                              rows="3"
                                              placeholder="Message"
                                              onChange={handleChange}
                                    ></textarea>
                                    <button className="get-tickets _small" type="submit">
                                        SEND
                                        <ArrowBlank className="get-tickets__arrow" />
                                    </button>
                                </form>
                                <InfoPopup
                                    setIsPopupOpen={isPopupOpen}
                                    socialList={socialList}
                                    modalContent={modalContent}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts
