import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from 'gatsby';

import "../scss/main.scss";
import Contacts from "../components/contacts";
import { FRONT_DOMAIN } from "../config";

const VisitPage = ({location, pageContext: {contacts}}) => {

    useEffect(() => {
        if(location.state?.setIsPopupOpen) {
            location.state.setIsPopupOpen(false)
        }
    }, [])
    return (
        <>
            <Helmet>
                <title>Contacts | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <link href='https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css' rel='stylesheet' />
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <Contacts socialList={contacts.items}/>
        </>
    )
}

export default VisitPage;
